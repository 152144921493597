<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Sort each of the following elements in order of
        <span class="text-bold">increasing atomic size</span>
        (i.e. smallest to largest):
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="itemList"
        class="mb-0"
        style="max-width: 425px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

export default {
  name: 'Question37',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      itemList: [
        '$\\text{Al}$',
        '$\\text{Ar}$',
        '$\\text{Cl}$',
        '$\\text{Mg}$',
        '$\\text{Na}$',
        '$\\text{P}$',
        '$\\text{S}$',
        '$\\text{Si}$',
      ],
    };
  },
};
</script>
